<template>
  <div class="test">
    <div class="main" :style="icon">
      <div class="sin">
        <div class="canvasNumber">
          <span>{{ creationNumber }}</span>
          <span v-if="h&&m&&s">{{ h }}:{{ m }}:{{ s }}</span>
        </div>
        <div class="logo">
          <img
              v-if="start"
              class="pic2"
              src="../assets/image/home/home1.png"
          />
          <img
              v-else
              class="pic2"
              src="../assets/image/home/home2.png"
          />
          <div class="imageUrl" :style="imageUrl" v-if="!start"></div>
        </div>
      </div>
      <div class="sin" v-if="false">
        <div class="TitleNumber">
          <i class="f">00:00:00</i>
          <i>活动</i>
          <i>倒计时</i>
        </div>
        <div class="Number">1
          <div class="s">天</div>
        </div>
      </div>
      <div class="sin" v-if="false">
        <div class="Big">100000</div>
        <div class="Button">进入</div>
      </div>
    </div>
  </div>
</template>

<script>

import {initHome} from "../api/home";

export default {
  data() {
    return {
      start: false,
      imageUrl: '',
      icon: '',
      creationNumber: 0,
      h: '',
      m: '',
      s: '',
      timeEnd: ''
    }
  },
  created() {
    this.countTime()
    this.time = setInterval(() => {
      this.countTime()
    }, 1000);
    initHome().then(res => {
      let timeEnd = res.timeEnd
      let imageUrl = res.imageUrl
      let creationNumber = this.getNunber(res.creationNumber)
      this.$store.state.creationNumber = creationNumber
      this.creationNumber = creationNumber
      this.timeEnd = timeEnd
      this.imageUrl = " background-image: url(" + imageUrl + ");"
    })
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  mounted() {

    let SidNumber = this.$route.query.SidNumber

    if (SidNumber) {
      this.$router.push({
        path: '/login',
        query: {
          SidNumber
        }
      })
    } else {
      let ins = this.getRandom(0, 2)
      if (ins == 0) {
        this.start = true
      } else {
        this.start = false
      }
      setTimeout(() => {
        this.icon = 'opacity:1'
      }, 1)
      setTimeout(() => {
        this.icon = 'opacity:0;transition: 2s;'
      }, 3000)
      setTimeout(() => {
        this.$router.push('/login')
      }, 5000)
    }
  },
  methods: {
    countTime() {
      //获取当前时间
      let date = new Date();
      let now = date.getTime();
      //设置截止时间
      let endDate = new Date(this.timeEnd);
      let end = endDate.getTime();
      //时间差
      let differTime = end - now;
      //定义变量,h,m,s保存倒计时的时间
      let h, m, s;
      if (differTime >= 0) {
        h = Math.floor(differTime / 1000 / 60 / 60);
        m = Math.floor(differTime / 1000 / 60 % 60);
        s = Math.floor(differTime / 1000 % 60);
        this.h = h < 10 ? ("0" + h) : h;
        this.m = m < 10 ? ("0" + m) : m;
        this.s = s < 10 ? ("0" + s) : s;
      } else {
        this.h = "00";
        this.m = "00";
        this.s = "00";
      }
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    }
  }
}

</script>


<style lang="scss">


@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

.test {
  width: 100%;
  height: 100%;

  .main {
    width: 300px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -200px;


    .sin {
      width: 100%;
      height: 100%;

      .canvasNumber {
        font-family: intext;
        font-size: 25px;
        color: #1989fa;
        display: flex;
        justify-content: space-between;
      }

      .logo {
        width: 100%;
        height: 300px;
        position: absolute;
        top: 50px;

        img {
          width: 100%;
          height: 100%;
        }

        .imageUrl {
          width: 44px;
          height: 30px;
          position: absolute;
          right: 19px;
          top: 16px;
          background-size: 100% 100%;
        }
      }

      .TitleNumber {
        width: 140px;
        margin: 0 auto;
        font-size: 25px;
        color: #666666;
        font-family: intext;
        text-align: center;

        i {
          width: 100%;
          height: 50px;
          line-height: 50px;
          display: inline-block;
          text-align: justify;
          text-align-last: justify;
          font-style: normal;
        }

        .f {
          letter-spacing: 0;
          color: #eb5d69;
        }

      }

      .Number {
        width: 140px;
        height: 120px;
        line-height: 120px;
        font-family: intext;
        font-size: 100px;
        color: #eb5d69;
        text-align: center;
        position: relative;
        margin: 0 auto;

        .s {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 14px;
          height: 20px;
          line-height: 20px;
        }
      }

      .Big {
        width: 100%;
        height: 200px;
        line-height: 200px;
        text-align: center;
        color: #1989fa;
        font-size: 40px;
        font-family: intext;
      }

      .Button {
        background-color: #1989fa;
        color: #FFFFFF;
        text-align: center;
        font-size: 15px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        margin: 0 auto;
      }
    }

  }

}
</style>
